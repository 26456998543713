<template>
  <div>
    <nav v-if="this.user.loggedIn === true" class="navbar navbar-expand-lg navbar-light bg-primary mb-3">
      <div class="container-fluid">
      <router-link class="navbar-brand d-flex text-white" to="/">
        <Logo :fill="'#ffffff'" :height="'33px'" :width="'33px'" class="mb-0 mr-2 d-flex align-items-center"></Logo>
        Apple-Trademaster
      </router-link>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto ml-3">
            <template v-if="this.user.loggedIn === true">
              <b-nav-item>
                <router-link to="/home">Startseite</router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link to="/incoming-goods">Wareneingänge</router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link to="/otr">On the Road</router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link to="/dn-creation">DN Creation</router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link to="/fgr-import">FGR Importieren</router-link>
              </b-nav-item>
              <!-- <b-nav-item> -->
                <a class="nav-link" :href="`${URL}/?Token=${token}`" target="_blank">T4R</a>
              <!-- </b-nav-item> -->
              <b-nav-item v-if="this.user.data.admin">
                <router-link to="/settings">Einstellungen</router-link>
              </b-nav-item>
            </template>
          </ul>
        </div>  
        <div>
          <template v-if="this.user.loggedIn === true">
            <a class="nav-link" @click.prevent="signOut"><font-awesome-icon :icon="['fas', 'sign-out']" /></a>
          </template>
        </div>
      </div>
    </nav>
     <!-- <CheckUserName/> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Logo from '../assets/Logo'
import store from '../store'

import { auth, functionsT4R  } from "../plugins/firebase"
import { signOut } from "firebase/auth"
import { httpsCallable } from "firebase/functions";

export default {
  name: 'NavbarComponent',
  computed: {
    ...mapGetters({
// map `this.user` to `this.$store.getters.user`
      user: "user"
    })
  },
  data(){
    return{
      token: null,
      URL: location.hostname === 'localhost' ? 'http://localhost:8080' :'https://trademaster4reseller.comline.app',
      wakeLock: null,
      wakeLockEnabled: false
    }
  },
  components: {
    Logo,
    // CheckUserName
  },
  methods: {
    async signOut() {
      await store.dispatch("fetchUser", null)
      signOut(auth)
      .then(() => {
        this.user = false
        this.$router.replace({
          name: "login"
        });
      });
    },
    loginToT4R(){
      if(auth.currentUser){
        const uid = auth.currentUser?.uid

        let customToken = httpsCallable(functionsT4R, 'generateCustomToken');
        customToken(uid)
        .then((data)=>{
          this.token = data.data
        })
        .catch((err)=>{
          console.log(err)
        })
      }
    }
  },
  mounted(){
    window.setTimeout( function() {
      window.location.reload();
    }, 1500000); // 25 Minuten in Millisekunden (25 * 60 * 1000)
  },
  beforeMount(){
    this.loginToT4R();
  },
}
</script>
<style>
.navbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
a.nav-link,
li a {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.788) !important;
}
a.nav-link:hover,
li a:hover {
  color: rgb(255, 255, 255) !important;
}
</style>