import { render, staticRenderFns } from "./Settings.vue?vue&type=template&id=989a8514&scoped=true&v-if=this.user.loggedIn"
import script from "./Settings.vue?vue&type=script&lang=js"
export * from "./Settings.vue?vue&type=script&lang=js"
import style0 from "./Settings.vue?vue&type=style&index=0&id=989a8514&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "989a8514",
  null
  
)

export default component.exports